import * as React from "react"


const NotFoundPage = () => {
  return (
    <main>
    </main>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
